import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
// import en from '../_CMS_/translations/en.json'
import AsyncBackend from 'i18next-async-backend';
var defaultLanguage = 'en_US';
if (window.location.search.length > 0) {
    var search = new URLSearchParams(window.location.search);
    if (search.get('lang')) {
        defaultLanguage = search.get('lang');
    }
}
export function getCurrentLocale() {
    return defaultLanguage;
}
var i18n = i18next.createInstance().use(AsyncBackend);
export default i18n;
export var initI18n = function () {
    initReactI18next.init(i18n);
    return i18n.init({
        backend: {
            resources: {
                // en: loadEnWithDelay,
                en: function () { return fetch('data/en.json').then(function (r) { return r.json(); }); },
                // en: () => import('../_CMS_/translations/en.json'),
            }
        },
        lng: 'en',
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // Not needed for React as it escapes by default
        },
    });
};
/*
i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    //   .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // @ilya please help here
    // todo: load generated json from public/data/jsonexporttest.json
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'en_US',
        lng: defaultLanguage,
        // supportedLngs,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            // 'nl': { translation: nl },
            'en_US': { translation: en }
        }
        // 	'en_AE': { translation: en_AE },
        // 	'ar_AE': { translation: ar_AE },
        // 	'en_CA': { translation: en_CA },
        // 	'fr_CA': { translation: fr_CA },
        // 	'fr_CH': { translation: fr_CH },
        // 	'de_CH': { translation: de_CH },
        // 	'de_DE': { translation: de_DE },
        // 	'de_AT': { translation: de_AT },
        // 	'da_DK': { translation: da_DK },
        // 	'es_ES': { translation: es_ES },
        // 	'fr_FR': { translation: fr_FR },
        // 	'en_IE': { translation: en_IE },
        // 	'it_IT': { translation: it_IT },
        // 	'es_MX': { translation: es_MX },
        // 	'no_NO': { translation: no_NO },
        // 	'pl_PL': { translation: pl_PL },
        // 	'pt_PT': { translation: pt_PT },
        // 	'ru_RU': { translation: ru_RU },
        // 	'sv_SE': { translation: sv_SE },
        // 	'en_GB': { translation: en_GB },
        // 	'en_US': { translation: en_US },
        // 	'en_ZA': { translation: en_ZA },
        // 	'en_SA': { translation: en_SA },
        // 	'ar_SA': { translation: ar_SA },
        // 	'fi_FI': { translation: fi_FI },
        // 	'en_AU': { translation: en_AU },
        // 	'zh_HK': { translation: zh_HK },
        // 	'en_HK': { translation: en_HK },
        // 	'en_IN': { translation: en_IN },
        // 	'ja_JP': { translation: ja_JP },
        // 	'ko_KR': { translation: ko_KR },
        // 	'en_MY': { translation: en_MY },
        // 	'en_NZ': { translation: en_NZ },
        // 	'en_SG': { translation: en_SG },
        // 	'th_TH': { translation: th_TH },
        // 	'zh_TW': { translation: zh_TW },
        // 	'id_ID': { translation: id_ID },

        // }
    });

export default i18n;
*/
